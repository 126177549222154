import {
  ChangeDetectorRef,
  OnDestroy,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { LocalizationService } from '../services/translation/localization.service';
import { Localized } from '../models/localized';
import { isString } from 'lodash-es';
import { NaooStringDefaulter } from '../string-defaulter/naoo-string-defaulter';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Pipe({
  name: 'naooLocalizedOrStringDefaulter',
  pure: false,
  standalone: true,
})
export class NaooLocalizedOrStringDefaulterPipe
  implements PipeTransform, OnDestroy
{
  private readonly stringDefaulter: NaooStringDefaulter =
    new NaooStringDefaulter();
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly localizationService: LocalizationService,
    private readonly _ref?: ChangeDetectorRef,
  ) {
    this.localizationService
      .language()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this._ref) {
          this._ref.markForCheck();
        }
      });
  }

  transform(value: Localized<string> | string): string | undefined {
    if (isString(value)) {
      return this.localizationService.instant(value);
    }
    return this.stringDefaulter.getString(
      value,
      this.localizationService.currentLanguage,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
