@if (menuElement?.menuElements?.length) {
  <button mat-menu-item [matMenuTriggerFor]="appMenu" class="menu-button">
    {{ menuElement?.name | naooLocalizedOrStringDefaulter }}
  </button>
} @else if (menuElement) {
  <button mat-menu-item>
    @if (menuElement.isViewAll) {
      <div class="view-all-link">
        {{ 'HOME_PAGE.VIEW_ALL' | translate }}
      </div>
    } @else {
      <div class="menu-item">
        {{ menuElement?.name | naooLocalizedOrStringDefaulter }}
      </div>
    }
  </button>
}

<mat-menu #appMenu="matMenu" xPosition="after">
  @for (menuElement of limitedMenuElements; track menuElement.route) {
    @if (menuElement.menuElements?.length) {
      <button
        class="menu-element-button"
        mat-menu-item
        (click)="menuClicked($event, menuElementComponent)"
        (keydown.enter)="menuClicked($event, menuElementComponent)"
        (keydown.space)="menuClicked($event, menuElementComponent)"
      >
        <naoo-menu-element
          class="menu-element"
          #menuElementComponent
          [menuElement]="menuElement"
          [listSizeLimit]="listSizeLimit"
        >
        </naoo-menu-element>
      </button>
    } @else {
      <button
        mat-menu-item
        (click)="navigateToRoute(menuElement)"
        class="menu-element-button"
      >
        <naoo-menu-element
          class="menu-element"
          [menuElement]="menuElement"
          [listSizeLimit]="listSizeLimit"
        >
        </naoo-menu-element>
      </button>
    }
  }
</mat-menu>
