import { Action, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { defaultIfEmpty, of } from 'rxjs';
import {
  catchError,
  concatMap,
  delayWhen,
  first,
  map,
  mergeMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  selectHasPermissionEnabled,
  selectPrimarySiteId,
} from '../session/session.selectors';
import { CartReviewActions } from './cart-review.actions';
import {
  CartReview,
  selectCartDropshipMaterials,
  selectCartReview,
  selectCartReviewTransformerData,
  selectIsCartLoadedForActiveCustomer,
  selectIsCartReviewDataLoaded,
} from './cart-review.selectors';
import { CustomerPermission } from '../../services/session/models/session-record';
import {
  selectCartEntity,
  selectCartId,
  selectCurrentCartMaterialNumbers,
  selectDropShipSiteId,
  selectIsCartLoaded,
} from '../cart/cart.selectors';
import { PreSubmitService } from '../../services/pre-submit/pre-submit.service';
import {
  FulfillmentOrderSplit,
  OrderSplitRecord,
} from '../../services/pre-submit/model/order-split-record';
import {
  transformCartReview,
  transformCartReviewDropShip,
} from './transform/cart-review-transformer';
import { CartActions } from '../cart/cart.actions';
import { DefaultDialogService } from '../../../shared/services/dialog/default-dialog/default-dialog.service';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from '../../../shared/error-handler/NaooErrorUtils';
import { Router } from '@angular/router';
import { NaooConstants } from '../../../shared/NaooConstants';
import { InventoryAvailabilityActions } from '../inventory-availability/inventory-availability.actions';
import { SharedActions } from '../shared/shared.actions';
import {
  CartReviewMetadata,
  CartReviewSection,
  CartReviewSectionName,
} from './cart-review.state';
import {
  SplitOrder,
  SplitOrderType,
} from '../../services/cart-order/models/cart-order';
import moment from 'moment';

@Injectable()
export class CartReviewEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private preSubmitService: PreSubmitService,
    private defaultDialogService: DefaultDialogService,
    private router: Router,
  ) {}

  loadCartReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.loadCartReview),
      concatMap(() => {
        this.defaultDialogService.openLoadingModal();
        return [
          CartActions.resetSplitOrders(),
          CartReviewActions.loadCartReviewCartData(),
          CartReviewActions.getCartReviewMetadata(),
          CartActions.openFulfillmentModal(),
        ];
      }),
    );
  });

  loadCartReviewCartData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.loadCartReviewCartData),
      delayWhen(() =>
        this.store.select(selectIsCartLoaded).pipe(
          first((cart) => !!cart),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CartReviewActions.clearCartReview,
                CartReviewActions.loadCartReview,
              ),
            ),
          ),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectCurrentCartMaterialNumbers),
        this.store.select(selectIsCartLoaded),
      ]),
      map(([_, materialNumbers, isCartLoaded]) => {
        return isCartLoaded
          ? InventoryAvailabilityActions.loadCartInventoryAvailability(
              materialNumbers,
            )
          : SharedActions.noOperation(
              'loadCartReviewCartData$: cart is not loaded',
            );
      }),
    );
  });

  refreshDropShipMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.refreshDropShipMetadata),
      concatLatestFrom(() => [
        this.store.select(selectCartReviewTransformerData),
        this.store.select(selectDropShipSiteId),
        this.store.select(selectPrimarySiteId),
        this.store.select(selectCartId),
      ]),
      concatMap(
        ([_, cartReviewData, dropShipSiteId, primarySiteId, cartId]) => {
          const siteId = dropShipSiteId || primarySiteId;
          this.defaultDialogService.openLoadingModal();
          return this.preSubmitService
            .getFulfillmentSplits(cartId, { siteId })
            .pipe(
              map((orderSplitRecord: OrderSplitRecord) => {
                const metadata = CartReviewEffects.buildCartReviewMetadata(
                  orderSplitRecord,
                  siteId,
                );
                const dropShipData = transformCartReviewDropShip(
                  cartReviewData,
                  metadata,
                );
                return CartReviewActions.refreshDropShipMetadataSuccess(
                  dropShipData,
                );
              }),
              catchError(() => {
                this.defaultDialogService.closeLoadingModal();
                return of(CartReviewActions.getDropShipMetadataFailure());
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    CartReviewActions.clearCartReview,
                    CartReviewActions.loadCartReview,
                    CartReviewActions.refreshDropShipMetadata,
                  ),
                ),
              ),
            );
        },
      ),
    );
  });

  refreshDropShipMetadataSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CartReviewActions.refreshDropShipMetadataSuccess),
        tap(() => {
          this.defaultDialogService.closeLoadingModal();
        }),
      );
    },
    { dispatch: false },
  );

  getCartReviewMetadata$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.getCartReviewMetadata),
      delayWhen(() =>
        this.store.select(selectIsCartLoadedForActiveCustomer).pipe(
          first(
            (isCartLoadedForActiveCustomer) => isCartLoadedForActiveCustomer,
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CartReviewActions.clearCartReview,
                CartReviewActions.loadCartReview,
              ),
            ),
          ),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectDropShipSiteId),
        this.store.select(selectPrimarySiteId),
        this.store.select(selectCartEntity),
        this.store.select(selectIsCartLoadedForActiveCustomer),
        this.store.select(
          selectHasPermissionEnabled(CustomerPermission.InvoiceCustomer),
        ),
      ]),
      concatMap(
        ([
          _,
          dropShipSiteId,
          primarySiteId,
          cartEntityState,
          isCartLoadedForActiveCustomer,
          isInvoiceCustomer,
        ]) => {
          if (!isCartLoadedForActiveCustomer) {
            return of(
              SharedActions.noOperation('Cart Review metadata aborted.'),
            );
          }

          const siteId = dropShipSiteId || primarySiteId;
          return this.preSubmitService
            .getFulfillmentSplits(cartEntityState.id, { siteId })
            .pipe(
              mergeMap((orderSplitRecord: OrderSplitRecord) => {
                const cartUpdateAction =
                  this.createUpdatePickupStoreFulfillmentForCartReview(
                    orderSplitRecord,
                    cartEntityState,
                  );

                const cartReviewAction = CartReviewActions.buildCartReview(
                  CartReviewEffects.buildCartReviewMetadata(
                    orderSplitRecord,
                    siteId,
                  ),
                );

                return cartUpdateAction
                  ? [cartUpdateAction, cartReviewAction]
                  : [cartReviewAction];
              }),
              catchError((error) => {
                const naooError = NaooErrorUtils.getNaooError(error);
                const isPaymentRequired = !isInvoiceCustomer;
                if (
                  isPaymentRequired ||
                  naooError.code === NAOOErrorCode.REQUIRED_PAYMENT_UNAVAILABLE
                ) {
                  this.router.navigate([NaooConstants.CART_PATH]);
                  this.defaultDialogService.defaultErrorModal(
                    naooError.code,
                    true,
                  );
                }
                return of(CartReviewActions.getDropShipMetadataFailure());
              }),
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    CartReviewActions.clearCartReview,
                    CartReviewActions.loadCartReview,
                  ),
                ),
              ),
            );
        },
      ),
    );
  });

  getDropshipMetatdataFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.getDropShipMetadataFailure),
      delayWhen(() =>
        this.store.select(selectIsCartReviewDataLoaded).pipe(
          first((isCartReviewDataLoaded) => isCartReviewDataLoaded),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CartReviewActions.clearCartReview,
                CartReviewActions.loadCartReview,
              ),
            ),
          ),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectCartDropshipMaterials),
        this.store.select(selectIsCartReviewDataLoaded),
      ]),
      mergeMap(([_, dropShipMaterials, isCartReviewDataLoaded]) => {
        if (!isCartReviewDataLoaded) {
          return of(SharedActions.noOperation('Build Cart Review aborted.'));
        }
        return [
          CartReviewActions.buildSystemError(dropShipMaterials),
          CartReviewActions.buildCartReview(undefined),
        ];
      }),
    );
  });

  buildCartReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.buildCartReview),
      delayWhen(() =>
        this.store.select(selectIsCartReviewDataLoaded).pipe(
          first((isCartReviewDataLoaded) => isCartReviewDataLoaded),
          takeUntil(
            this.actions$.pipe(
              ofType(
                CartReviewActions.clearCartReview,
                CartReviewActions.loadCartReview,
              ),
            ),
          ),
          defaultIfEmpty(true),
        ),
      ),
      concatLatestFrom(() => [
        this.store.select(selectCartReviewTransformerData),
        this.store.select(selectIsCartReviewDataLoaded),
        this.store.select(selectCartReview),
      ]),
      mergeMap(
        ([
          action,
          cartReviewTransformerData,
          isCartReviewDataLoaded,
          cartReviewData,
        ]) => {
          if (!isCartReviewDataLoaded) {
            return of(SharedActions.noOperation('Build Cart Review aborted.'));
          }
          cartReviewTransformerData.failedDropShipSplitMaterials =
            cartReviewData?.failedDropShipSplitMaterials;

          const data = transformCartReview(
            cartReviewTransformerData,
            action.metadata,
            cartReviewData?.isOffline,
          );

          const actions: Action[] = this.buildDropShipSplitOrderUpdates(data);
          actions.push(CartReviewActions.buildCartReviewSuccess(data));

          this.defaultDialogService.closeLoadingModal();
          return actions;
        },
      ),
    );
  });

  removeFailedFulfillmentMaterials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.removeFailedFulfillmentMaterials),
      concatLatestFrom(() => this.store.select(selectCartReview)),
      mergeMap(([_, cartReview]) => {
        const removeActions: Action[] =
          cartReview.failedDropShipSplitMaterials.map((materialId) =>
            CartActions.deleteCartMaterial(materialId),
          );

        removeActions.push(
          CartReviewActions.removeFailedFulfillmentMaterialsSuccess(),
        );

        return removeActions;
      }),
    );
  });

  clearCartReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.clearCartReview),
      mergeMap((_) => {
        this.defaultDialogService.closeLoadingModal();
        return [
          CartActions.resetSplitOrders(),
          CartActions.clearDeletedCartMaterials(),
        ];
      }),
    );
  });

  updateCartReviewSection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.updateCartReviewSection),
      mergeMap((action) => {
        const changes = !action.isSplit ? action.changes : {};
        const store = changes.store;

        if (changes.expressResolutionDate) {
          return [
            CartActions.updateExpressStoreFulfillment(
              changes.expressResolutionDate,
            ),
          ];
        } else if (!!store && !!changes.pickupResolutionDate) {
          return [
            CartActions.updatePickupStoreFulfillmentForCartReview(
              store.storePlantId,
              changes.pickupResolutionDate,
            ),
          ];
        } else {
          return [
            SharedActions.noOperation('updateCartReviewSection$ nothing to do'),
          ];
        }
      }),
    );
  });

  refreshCartReview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CartReviewActions.refreshCartReview),
      mergeMap((_) => {
        this.defaultDialogService.openLoadingModal();
        return [CartReviewActions.loadCartReview()];
      }),
    );
  });

  private buildDropShipSplitOrderUpdates(cartReview: CartReview): Action[] {
    return (
      cartReview.groups
        .find(
          (group) => CartReviewSectionName.DropShipItemsSection === group.name,
        )
        ?.sections.map((section) =>
          CartActions.updateSplitOrder(this.buildDropShipSplitOrder(section)),
        ) || []
    );
  }

  private buildDropShipSplitOrder(section: CartReviewSection): SplitOrder {
    return {
      orderType: SplitOrderType.DROP_SHIP,
      customerPoNumber: section.poNumber,
      materialNumbers: section.materialNumbers.map(
        (row) => row.value as string,
      ),
      carrierFulfillment: {
        shipmentId: section.fulfillmentOrderSplit?.shipmentId,
        deliverySiteId: undefined,
        carrierDeliveryTypeCode: undefined,
        carrierShippingMethodCode: undefined,
        shippingPrice: undefined,
      },
    };
  }

  private createUpdatePickupStoreFulfillmentForCartReview(
    orderSplitRecord: OrderSplitRecord,
    cartEntityState: any,
  ): Action | undefined {
    const hasIspuRequestedTimestamp =
      !!cartEntityState?.storeFulfillment?.requestedPickupTimestamp;

    if (hasIspuRequestedTimestamp) {
      const suggestedTime = moment(
        orderSplitRecord.storeOrderSplitOptions.storeOrderOptions[0]
          .splitsForStoreOrderOption[0].suggestedAvailabilityDateTime,
      );

      const existingTime = moment(
        cartEntityState.storeFulfillment.requestedPickupTimestamp,
      );

      const isSameDay = existingTime.isSame(suggestedTime, 'day');
      const isBeforeSuggestedTime = existingTime.diff(suggestedTime) < 0;

      if (isSameDay && isBeforeSuggestedTime) {
        return CartActions.updatePickupStoreFulfillmentForCartReview(
          cartEntityState.storeFulfillment.storePlantId,
          suggestedTime,
        );
      }
    }
    return undefined;
  }

  private static buildCartReviewMetadata(
    orderSplitRecord: OrderSplitRecord,
    siteId: string,
  ): CartReviewMetadata {
    const fulfillmentOrderSplits: FulfillmentOrderSplit[] =
      orderSplitRecord.fulfillmentOrderSplits?.fulfillmentOrderSplits;
    fulfillmentOrderSplits?.forEach(
      (split) =>
        (split.freightOptions = [...split.freightOptions].sort(
          (fo1, fo2) => fo1.freightCost - fo2.freightCost,
        )),
    );

    return {
      cutoffSplitRecord: orderSplitRecord.cutoffOrderSplits,
      fulfillmentOrderSplits,
      failedMaterials: orderSplitRecord.failedMaterials,
      selectedDropShipSiteId: siteId,
      storeOrderSplitOptions: orderSplitRecord.storeOrderSplitOptions,
      paymentInfo: orderSplitRecord.paymentInfo,
    };
  }
}
