import { takeUntil } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { FulfillmentModalService } from '../../shared/services/fulfillment-modal/fulfillment-modal.service';
import { FulfillmentType } from '../../core/services/cart/models/cart-record';
import { BreakpointObserver } from '@angular/cdk/layout';
import { OrderMethodWidgetFacade } from '../../core/store/select-order-method-widget/order-method-widget.facade';
import { OrderMethodWidgetViewModel } from '../../core/store/select-order-method-widget/order-method-widget-view-model';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { NaooDatePipe } from '../../shared/pipes/naoo-date.pipe';
import { NaooTimeRangePipe } from '../../shared/pipes/naoo-time-range.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-select-order-method-widget',
  templateUrl: './select-order-method-widget.component.html',
  styleUrls: ['./select-order-method-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatIcon, NaooDatePipe, NaooTimeRangePipe, TranslateModule],
})
export class SelectOrderMethodWidgetComponent implements OnInit, OnDestroy {
  private readonly mobileBreakPoint = '(max-width: 600px)';
  readonly validCalendar = 'calendar-icon-v3';
  readonly invalidCalendar = 'calendar-invalid';

  @Input() wideLayout: boolean;
  @Input() disabled: boolean;
  @Input() prefix: string;

  isMobile: boolean;
  viewModel: OrderMethodWidgetViewModel;

  private destroyed$ = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private fulfillmentModalService: FulfillmentModalService,
    private orderMethodWidgetFacade: OrderMethodWidgetFacade,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.orderMethodWidgetFacade
      .getViewModel()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((viewModel) => {
        this.viewModel = viewModel;
        this.changeDetectorRef.markForCheck();
      });

    this.breakpointObserver
      .observe(this.mobileBreakPoint)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.isMobile = result.breakpoints[this.mobileBreakPoint];
        this.changeDetectorRef.markForCheck();
      });
  }

  get FulfillmentType(): typeof FulfillmentType {
    return FulfillmentType;
  }

  get isFulfillmentTypeExpress(): boolean {
    return FulfillmentType.EXPRESS === this.viewModel?.fulfillmentType;
  }

  get isFulfillmentTypeNone(): boolean {
    return FulfillmentType.NONE === this.viewModel?.fulfillmentType;
  }

  get icon(): string {
    return this.isFulfillmentTypeNone
      ? this.invalidCalendar
      : this.validCalendar;
  }

  get title(): string {
    switch (this.viewModel?.fulfillmentType) {
      case FulfillmentType.PICKUP:
        return 'SELECT_ORDER_METHOD.IN-STORE_PICKUP';
      case FulfillmentType.EXPRESS:
        return 'SELECT_ORDER_METHOD.EXPRESS_DELIVERY';
      case FulfillmentType.TRUCK:
        return 'SELECT_ORDER_METHOD.TRUCK_DELIVERY';
      default:
        return 'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_TITLE';
    }
  }

  get displayingDateOnly(): boolean {
    return this.isMobile && !this.isFulfillmentTypeNone;
  }

  openFulfillmentModal(): void {
    this.fulfillmentModalService.openFulfillmentModal(true, true, true);
  }
}
