import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WebBffService } from 'src/app/shared/services/web-bff/web-bff.service';
import { MatDialog } from '@angular/material/dialog';
import {
  CartOrderDestinationSystem,
  CartOrderMaterialRecord,
  CartOrderResponse,
  CartOrderStatus,
  SplitOrderRequest,
  SubmitRecord,
} from 'src/app/core/services/cart-order/models/cart-order';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap } from 'rxjs/operators';
import { NaooConstants } from 'src/app/shared/NaooConstants';
import { MaterialCutoffFacade } from '../../store/material-cutoff/material-cutoff.facade';
import {
  NAOOErrorCode,
  NaooErrorUtils,
} from 'src/app/shared/error-handler/NaooErrorUtils';
import { DefaultDialogService } from 'src/app/shared/services/dialog/default-dialog/default-dialog.service';
import { Router } from '@angular/router';
import { CartFacade } from '../../store/cart/cart.facade';
import { NaooAnalyticsManager } from 'src/app/shared/analytics/NaooAnalyticsManager';
import { AnalyticsEventInfo } from 'src/app/shared/analytics/analytics-event-info';
import { OrderConfirmationService } from '../order-confirmation/order-confirmation.service';
import {
  OrderConfirmationCompleteResponse,
  OrderConfirmationPollRecord,
} from '../order-confirmation/models/order-confirmation-record';
import { SessionFacade } from '../../store/session/session.facade';
import { ModalFacade } from '../../store/modal/modal.facade';
import { MultipleCartsFacade } from '../../store/multiple-carts/multiple-carts.facade';
import { Cart } from '../../../shared/models/cart';
import { formatDate } from '../../../shared/utilities/date-utilities';
import { NaooError } from '../../../shared/models/naoo-error';
import { CustomDialogService } from '../../../shared/services/dialog/custom-dialog/custom-dialog.service';
import { CartReviewFacade } from '../../store/cart-review/cart-review.facade';
import { FulfillmentModalService } from '../../../shared/services/fulfillment-modal/fulfillment-modal.service';

type SubmitResponse = [
  CartOrderResponse | OrderConfirmationCompleteResponse,
  Date,
];

@Injectable({
  providedIn: 'root',
})
export class CartOrderService {
  private readonly pollingInitialDelay = 2500;
  private readonly pollingIntervalDelay = 750;
  private readonly pollingTimeout = 20000;
  private readonly orderSubmissionFailureTag = 'order submission';
  private selectedCreditCardOption: boolean;

  private isPunchOut: boolean;

  // eslint-disable-next-line max-params
  constructor(
    private readonly httpClient: HttpClient,
    private readonly webBffService: WebBffService,
    private readonly cartFacade: CartFacade,
    private readonly cartReviewFacade: CartReviewFacade,
    private readonly matDialog: MatDialog,
    private readonly materialCutoffFacade: MaterialCutoffFacade,
    private readonly defaultDialogService: DefaultDialogService,
    private readonly router: Router,
    private readonly analyticsManager: NaooAnalyticsManager,
    private readonly fulfillmentModalService: FulfillmentModalService,
    private readonly orderConfirmationService: OrderConfirmationService,
    private readonly sessionFacade: SessionFacade,
    private readonly modalFacade: ModalFacade,
    private readonly multipleCartsFacade: MultipleCartsFacade,
    private readonly customDialogService: CustomDialogService,
  ) {
    this.sessionFacade.getLoadedSession().subscribe((sessionInfo) => {
      this.isPunchOut = sessionInfo.isPunchOut;
    });
    this.cartReviewFacade
      .isCreditCardOptionSelected()
      .subscribe((isCreditCardOptionSelected) => {
        this.selectedCreditCardOption = isCreditCardOptionSelected;
      });
  }

  submit(): Observable<SubmitResponse> {
    this.customDialogService.openSubmitLoadingModal();
    const cart$: Observable<Cart> = this.cartFacade
      .getLoadedCart()
      .pipe(first());
    const doneUpdating$: Observable<boolean> = this.cartFacade
      .isDoneUpdating()
      .pipe(first((isDoneUpdating) => !!isDoneUpdating));

    const cartReview$ = this.cartReviewFacade
      .getLoadedCartReview()
      .pipe(first());

    return forkJoin([cart$, doneUpdating$, cartReview$]).pipe(
      mergeMap(([cart, _, cartReview]) => {
        if (!!cart && cart.materials.length === 0) {
          this.customDialogService.closeSubmitLoadingModal();
          this.router.navigate([NaooConstants.ORDERS_PATH]);
          return EMPTY;
        }

        this.cartFacade.submitCartEvent();
        const checkoutId = cartReview?.storeOrderPaymentDetails?.checkoutId;
        return this.submitCall(cart, checkoutId).pipe(
          catchError((error) =>
            this.resolveSubmissionError(error, !!checkoutId),
          ),
          mergeMap((response) => {
            return this.pollForOrders(response.cartOrderIds, cart);
          }),
          map(
            (
              response: CartOrderResponse | OrderConfirmationCompleteResponse,
            ): SubmitResponse => [response, cart?.truckFulfillment?.routeDate],
          ),
        );
      }),
    );
  }

  markSubmitModalDone(): void {
    this.customDialogService.markSubmitModalDone();
  }

  private navigateToCartPage(): void {
    this.router.navigate([NaooConstants.CART_PATH]);
  }

  private resolveSubmissionError(
    error: HttpErrorResponse,
    isCreditPayment: boolean,
  ): Observable<never> {
    if (!isCreditPayment) {
      this.navigateToCartPage();
    }
    this.handleError(error);
    return EMPTY;
  }

  private static createProcessingCartOrder(
    cart: Cart,
    isRetalix: boolean,
  ): CartOrderResponse {
    const materials: CartOrderMaterialRecord[] = cart.materials.map(
      (material) => {
        return {
          materialNumber: material.materialNumber,
          lines: material.lines.map((line) => {
            return {
              uom: line.uom,
              quantity: line.quantity,
            };
          }),
        };
      },
    );

    return {
      cartOrders: [
        {
          id: cart.id,
          destinationSystem: isRetalix
            ? CartOrderDestinationSystem.RETALIX_ORDER
            : null,
          status: CartOrderStatus.PROCESSING,
          materials: materials,
          cartOrderTruckFulfillment: {
            requestedRouteDate: formatDate(cart.routeDate),
          },
          customerPoNumber: cart.customerPo,
          submitTimestamp: new Date(),
        },
      ],
    };
  }

  private handleError(error: HttpErrorResponse): void {
    this.markSubmitModalDone();
    if (this.matDialog.openDialogs?.length) {
      this.matDialog.afterAllClosed.pipe(first()).subscribe(() => {
        this.displayModalForError(error);
      });
    } else {
      this.displayModalForError(error);
    }
  }

  private displayModalForError(httpError: HttpErrorResponse) {
    const naooError: NaooError = NaooErrorUtils.getNaooError(httpError);
    switch (naooError.code) {
      case NAOOErrorCode.COMMODITY_OVER_ALLOCATED:
        this.customDialogService.openMaterialOverallocationModal(
          naooError.message,
        );
        break;
      case NAOOErrorCode.DELIVERYSCHEDULE_CUTOFF:
        this.materialCutoffFacade.clearCache();
        this.fulfillmentModalService.openFulfillmentModal(
          true,
          false,
          false,
          false,
          false,
          true,
        );
        break;
      case NAOOErrorCode.CART_MAX_ITEM_COUNT:
        this.promptTooManyMaterialsModal();
        break;
      case NAOOErrorCode.CART_MAX_SPECIAL_ORDER_COUNT:
        this.promptTooManySpecialOrdersModal();
        break;
      case NAOOErrorCode.INACTIVE_CART:
        this.promptOneButtonErrorModal('CART.ERROR.INACTIVE_CART');
        break;
      case NAOOErrorCode.CART_ALREADY_SUBMITTED:
        this.isPunchOut
          ? this.promptOneButtonErrorModal('CART.ERROR.ALREADY_PUNCHED_OUT')
          : this.promptOneButtonErrorModal('CART.ERROR.ALREADY_SUBMITTED');
        break;
      case NAOOErrorCode.COMMODITY_SERVICE_UNAVAILABLE:
        this.promptOneButtonErrorModal(
          'ENTITLEMENT.COMMODITY_SERVICE_DOWN.MESSAGE',
          'ENTITLEMENT.COMMODITY_SERVICE_DOWN.CONFIRM',
        );
        break;
      case NAOOErrorCode.PRE_AUTHORIZATION_CARD_ERROR:
        this.promptOneButtonErrorModal('CART.ERROR.CARD_ERROR');
        break;
      case NAOOErrorCode.PRE_AUTHORIZATION_TRY_AGAIN_LATER:
        this.promptOneButtonErrorModal('CART.ERROR.TRY_AGAIN_LATER');
        break;
      case NAOOErrorCode.PRE_AUTHORIZATION_PAYMENT_INVALID:
        this.promptOneButtonErrorModal('CART.ERROR.PAYMENT_INVALID');
        break;
      case NAOOErrorCode.PRE_AUTHORIZATION_UNKNOWN_ERROR:
        this.promptOneButtonErrorModal('CART.ERROR.UNKNOWN_ERROR');
        break;
      case NAOOErrorCode.DELIVERY_WINDOW_NOT_AVAILABLE:
        this.promptOneButtonErrorModal(
          'CART.ERROR.DELIVERY_WINDOW_NOT_AVAILABLE',
        );
        break;
      case NAOOErrorCode.STORE_NOT_ACCEPTING_ORDERS:
        this.fulfillmentModalService.openFulfillmentModal(true, false, true);
        break;
      default:
        this.defaultDialogService.defaultErrorModal(
          this.orderSubmissionFailureTag,
        );
    }
  }

  private submitCall(
    cart: Cart,
    checkoutId?: string,
  ): Observable<SubmitRecord> {
    const url = `${this.webBffService.getBff()}/api/v6/cart/${cart.id}/submit`;
    const splitOrderRequest: SplitOrderRequest = {
      splitOrders: cart.splitOrders,
    };
    if (this.selectedCreditCardOption) {
      splitOrderRequest.creditCardCheckoutId = checkoutId;
    }
    return this.httpClient.post<SubmitRecord>(url, splitOrderRequest);
  }

  private statusCall(orderIds: string[]): Observable<CartOrderResponse> {
    const url = `${this.webBffService.getBff()}/api/v5/cart-orders`;
    return this.httpClient.post<CartOrderResponse>(url, orderIds);
  }

  private promptTooManyMaterialsModal() {
    this.modalFacade.promptOneButtonModal(
      'too-many-materials-display',
      'CART.ERROR.TOO_MANY_ITEMS.MESSAGE',
      'CART.ERROR.TOO_MANY_ITEMS.BUTTON_TEXT',
      () => {},
      true,
    );
    const eventInfo: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'submission',
      label: 'too many lines',
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private promptTooManySpecialOrdersModal() {
    this.modalFacade.promptOneButtonModal(
      'too-many-special-orders-display',
      'CART.ERROR.TOO_MANY_SPECIAL_ORDER_ITEMS.MESSAGE',
      'CART.ERROR.TOO_MANY_SPECIAL_ORDER_ITEMS.BUTTON_TEXT',
      () => {},
      true,
    );
    const eventInfo: AnalyticsEventInfo = {
      action: 'displayed',
      category: 'general',
      label: 'special orders limit',
    };
    this.analyticsManager.trackAnalyticsEvent(eventInfo);
  }

  private promptOneButtonErrorModal(
    messageData: string,
    buttonText: string = 'SHARED.MODALS.OK_BUTTON_TEXT',
  ) {
    this.modalFacade.promptOneButtonModal(
      'cart-order-error-refresh',
      messageData,
      buttonText,
      () => {
        this.cartFacade.refreshCart();
        this.multipleCartsFacade.refreshSavedCarts();
      },
      false,
    );
  }

  private aggregateMaterialInfoForProcessingOrders(
    orderConfirmationPollRecord: OrderConfirmationPollRecord,
  ) {
    return this.statusCall(orderConfirmationPollRecord.processingOrderIds).pipe(
      first(),
      map((processingCartOrderResponse) => {
        return {
          processingCartOrders: processingCartOrderResponse.cartOrders,
          orders: orderConfirmationPollRecord.orders,
        };
      }),
    );
  }

  private pollForOrders(
    cartOrderIds: string[],
    cartFallback: Cart,
  ): Observable<OrderConfirmationCompleteResponse> {
    return this.orderConfirmationService
      .pollForProcessedOrders(cartOrderIds)
      .pipe(
        concatMap((orderConfirmationPollResponse) => {
          if (orderConfirmationPollResponse.processingOrderIds.length > 0) {
            return this.aggregateMaterialInfoForProcessingOrders(
              orderConfirmationPollResponse,
            );
          }
          return of({
            processingCartOrders: [],
            orders: orderConfirmationPollResponse.orders,
          });
        }),
        catchError(() => {
          return of({
            processingCartOrders: CartOrderService.createProcessingCartOrder(
              cartFallback,
              false,
            ).cartOrders,
            orders: [],
          });
        }),
      );
  }
}
