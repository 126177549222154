import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { GenericMenuElement } from '../generic-menu.model';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NaooLocalizedOrStringDefaulterPipe } from '../../localized-string-defaulter/naoo-localized-or-string-defaulter.pipe';

@Component({
  selector: 'naoo-menu-element',
  standalone: true,
  imports: [
    MatMenuItem,
    TranslateModule,
    MatMenu,
    MatMenuTrigger,
    NaooLocalizedOrStringDefaulterPipe,
  ],
  templateUrl: './menu-element.component.html',
  styleUrl: './menu-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuElementComponent {
  @Input()
  menuElement: GenericMenuElement;
  @Input()
  listSizeLimit: number = 9;

  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;

  constructor(private readonly router: Router) {}

  get limitedMenuElements(): GenericMenuElement[] {
    return this.menuElement?.menuElements?.slice(0, this.listSizeLimit) || [];
  }

  toggleMenu(): void {
    this.matMenuTrigger?.openMenu();
  }

  navigateToRoute(menuElement: GenericMenuElement): void {
    this.router.navigateByUrl(menuElement.route);
  }

  menuClicked(event: Event, menu: MenuElementComponent): void {
    event.preventDefault();
    menu?.toggleMenu();
  }
}
