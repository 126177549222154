import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { naooAnimations } from 'src/app/shared/animations/animations';
import {
  MaterialLine,
  MaterialListStyle,
  MaterialRow,
  MaterialRowContext,
  MaterialRowStatus,
} from '../../../core/store/material-row/models/material-row';
import { NaooConstants } from '../../../shared/NaooConstants';
import { MaterialWarningType } from '../../../core/store/material-warning/material-warning';
import { MaterialAvailability } from '../../../shared/models/material-availability';
import { FulfillmentType } from '../../../core/services/cart/models/cart-record';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MaterialImageComponent } from '../shared/material-image/material-image.component';
import { MaterialStoreStockComponent } from '../shared/material-store-stock/material-store-stock.component';
import { MaterialInfoComponent } from '../shared/material-info/material-info.component';
import { MaterialOptionsMenuComponent } from '../shared/material-options-menu/material-options-menu.component';
import { MaterialOrderingComponent } from '../shared/material-ordering/material-ordering.component';
import { MaterialRowFooterComponent } from '../shared/material-row-footer/material-row-footer.component';
import { MaterialUnorderableComponent } from '../shared/material-unorderable/material-unorderable.component';
import { MaterialSimilarItemsComponent } from '../shared/material-similar-items/material-similar-items.component';
import { MaterialUnavailableComponent } from '../shared/material-unavailable/material-unavailable.component';
import { MaterialDeletedComponent } from '../shared/material-deleted/material-deleted.component';
import { MaterialSkeletonComponent } from '../shared/material-skeleton/material-skeleton.component';
import { MaterialFlagContainerComponent } from '../shared/material-flag-container/material-flag-container.component';
import { NaooPricePipe } from '../../../shared/pipes/naoo-price.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-material-row-list-content',
  templateUrl: './material-row-list-content.component.html',
  styleUrls: ['./material-row-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeInAndOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(250, style({ opacity: 0 }))]),
    ]),
    trigger('fadeInOnly', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(250, style({ opacity: 1 })),
      ]),
    ]),
    naooAnimations.fadeAndSlide,
  ],
  standalone: true,
  imports: [
    NgClass,
    NgTemplateOutlet,
    MaterialImageComponent,
    MaterialStoreStockComponent,
    MaterialInfoComponent,
    MaterialOptionsMenuComponent,
    MaterialOrderingComponent,
    MaterialRowFooterComponent,
    MaterialUnorderableComponent,
    MaterialSimilarItemsComponent,
    MaterialUnavailableComponent,
    MaterialDeletedComponent,
    MaterialSkeletonComponent,
    MaterialFlagContainerComponent,
    NaooPricePipe,
    TranslateModule,
  ],
})
export class MaterialRowListContentComponent {
  @Input() materialRow: MaterialRow;
  @Input() isLast: boolean;
  @Input() isMobile: boolean;
  @Input() materialAvailability: MaterialAvailability;
  @Input() fulfillmentType: FulfillmentType;
  @Input() displayGoPointsLogo: boolean;
  @Output() quantityInputChanged = new EventEmitter<MaterialLine>();
  @Output() viewCommodityDetailsClicked = new EventEmitter();

  readonly productDetailsPath = NaooConstants.PRODUCT_DETAILS_PATH;

  get isLoading(): boolean {
    return MaterialRowStatus.Loading === this.materialRow?.status;
  }

  get isOK(): boolean {
    return (
      MaterialRowStatus.Ok === this.materialRow?.status &&
      !this.isErrorProcessing &&
      !this.isDeleted
    );
  }

  get isErrorProcessing(): boolean {
    return (
      this.materialRow?.materialRowOptions?.context ===
      MaterialRowContext.ErrorProcessingItem
    );
  }

  get isCriticalItemContext(): boolean {
    return (
      this.materialRow?.materialRowOptions?.context ===
      MaterialRowContext.CriticalItem
    );
  }

  get isNonEditableItemContext(): boolean {
    return (
      this.materialRow?.materialRowOptions?.context ===
      MaterialRowContext.NonEditableItem
    );
  }

  get isUnorderable(): boolean {
    return (
      (MaterialRowStatus.Unorderable === this.materialRow?.status &&
        !this.isDeleted) ||
      (!this.isDeleted && this.isErrorProcessing)
    );
  }

  get isUnavailable(): boolean {
    return (
      MaterialRowStatus.Unavailable === this.materialRow?.status &&
      !this.isDeleted
    );
  }

  get isDeleted(): boolean {
    return this.materialRow?.isDeleted;
  }

  get isInsideContainer(): boolean {
    return [
      MaterialRowContext.CartReview,
      MaterialRowContext.Substitutes,
      MaterialRowContext.NonEditableItem,
      MaterialRowContext.PdpLinkItem,
      MaterialRowContext.OverallocationModal,
      MaterialRowContext.OrderDetailsEditModal,
    ].includes(this.materialRow?.materialRowOptions?.context);
  }

  get shouldShowRedBorder(): boolean {
    return (
      this.isOverAllocatedAndNonModalContext() ||
      this.hasMaterialErrorCartContext()
    );
  }

  get shouldHideShadow(): boolean {
    return (
      (this.isErrorProcessing && this.isLast) ||
      this.isCriticalItemContext ||
      (this.isCartReview && this.isLast) ||
      this.isNonEditableItemContext
    );
  }

  get shouldHideBottomBorder(): boolean {
    return (
      this.isLast &&
      MaterialRowContext.OverallocationModal !==
        this.materialRow?.materialRowOptions?.context
    );
  }

  get shouldShowGrayBorder(): boolean {
    return (
      !this.shouldShowRedBorder &&
      !this.shouldShowGreenBorder &&
      !this.isCriticalItemContext
    );
  }

  get shouldShowGreenBorder(): boolean {
    return (
      this.isCartReview && !!this.getSavingsTotal && !this.shouldShowRedBorder
    );
  }

  get getSavingsTotal(): number {
    return this.materialRow?.materialOrdering?.materialEstimatedSavings;
  }

  get isCartReview(): boolean {
    return (
      MaterialRowContext.CartReview ===
      this.materialRow?.materialRowOptions?.context
    );
  }

  get hasMaterialFooter(): boolean {
    return (
      MaterialListStyle.List ===
        this.materialRow?.materialRowOptions?.materialListStyle &&
      ![
        MaterialRowContext.CartReview,
        MaterialRowContext.CartSummary,
        MaterialRowContext.CriticalItem,
        MaterialRowContext.Substitutes,
        MaterialRowContext.NonEditableItem,
        MaterialRowContext.PdpLinkItem,
        MaterialRowContext.OverallocationModal,
        MaterialRowContext.ErrorProcessingItem,
        MaterialRowContext.OrderDetailsEditModal,
      ].includes(this.materialRow?.materialRowOptions?.context)
    );
  }

  get hasNoFlags(): boolean {
    return !this.materialRow?.flags || this.materialRow?.flags.length === 0;
  }

  get isParEnabled(): boolean {
    return this.materialRow?.materialRowOptions?.isParEnabled;
  }

  get displayMaxAllocation(): boolean {
    return (
      !this.isMobile &&
      this.materialRow?.materialRowOptions?.materialCommodityInfo
        ?.displayMaxAllocation &&
      !(
        MaterialRowContext.CustomGuide ===
          this.materialRow?.materialRowOptions?.context && this.isParEnabled
      )
    );
  }

  get isSubstitutesContext(): boolean {
    return (
      MaterialRowContext.Substitutes ===
      this.materialRow?.materialRowOptions?.context
    );
  }

  get shouldHideTrashCan(): boolean {
    return (
      this.materialRow?.materialRowOptions?.context ===
      MaterialRowContext.MarketingGuide
    );
  }

  private isOverAllocatedAndNonModalContext(): boolean {
    return (
      MaterialWarningType.OverAllocation ===
        this.materialRow?.materialOrdering?.materialWarning?.type &&
      MaterialRowContext.OverallocationModal !==
        this.materialRow?.materialRowOptions?.context
    );
  }

  private hasMaterialErrorCartContext() {
    return (
      (this.isUnavailable || this.isUnorderable) &&
      [MaterialRowContext.CartReview, MaterialRowContext.CartSummary].includes(
        this.materialRow?.materialRowOptions?.context,
      )
    );
  }

  protected readonly MaterialRowContext = MaterialRowContext;
}
