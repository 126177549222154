import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { GenericMenuElement, GenericMenuViewModel } from './generic-menu.model';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MenuElementComponent } from './menu-element/menu-element.component';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { NaooLocalizedOrStringDefaulterPipe } from '../localized-string-defaulter/naoo-localized-or-string-defaulter.pipe';

@Component({
  selector: 'naoo-generic-menu',
  standalone: true,
  imports: [
    MatMenu,
    MatMenuItem,
    TranslateModule,
    MatIcon,
    MatMenuTrigger,
    MenuElementComponent,
    NgClass,
    NaooLocalizedOrStringDefaulterPipe,
  ],
  templateUrl: './generic-menu.component.html',
  styleUrl: './generic-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericMenuComponent {
  @Input() menuModel: GenericMenuViewModel;
  @Input() isOffline: boolean = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private readonly router: Router) {}

  menuClicked(event: Event, menu: MenuElementComponent): void {
    event.preventDefault();
    menu.toggleMenu();
  }

  navigateToRoute(menuElement: GenericMenuElement): void {
    this.router.navigateByUrl(menuElement.route);
  }

  get limitedMenuElements(): GenericMenuElement[] {
    return (
      this.menuModel?.menuElements?.slice(0, this.menuModel.maxDisplayed) || []
    );
  }
}
