import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericNavigationElement } from './generic-navigation-model';
import { GenericNavigationMenuComponent } from './generic-navigation-menu/generic-navigation-menu.component';
import { Localized } from '../models/localized';
import { TranslateModule } from '@ngx-translate/core';
import { NaooLocalizedOrStringDefaulterPipe } from '../localized-string-defaulter/naoo-localized-or-string-defaulter.pipe';

@Component({
  selector: 'naoo-generic-navigation-modal',
  templateUrl: './generic-navigation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GenericNavigationMenuComponent,
    TranslateModule,
    NaooLocalizedOrStringDefaulterPipe,
  ],
})
export class GenericNavigationModalComponent implements OnInit {
  protected navigation: GenericNavigationElement;
  parentMenuName: string | Localized<string>;

  constructor(
    private readonly dialogRef: MatDialogRef<GenericNavigationModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    private readonly data: {
      parentMenuName: string | Localized<string>;
      menu: GenericNavigationElement;
    },
  ) {}

  ngOnInit(): void {
    this.navigation = this.data.menu;
    this.parentMenuName = this.data.parentMenuName;
  }

  closeModal() {
    this.dialogRef.close();
  }

  get dataNavigation() {
    return this.navigation;
  }
}
