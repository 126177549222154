import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialCutoff } from '../../../shared/models/material-cutoff';
import { Store } from '@ngrx/store';
import { MaterialCutoffActions } from './material-cutoff.actions';
import { selectMaterialCutoff } from './material-cutoff.selectors';

@Injectable({ providedIn: 'root' })
export class MaterialCutoffFacade {
  constructor(private readonly store: Store) {}

  getMaterialCutoffTime(cutoffCode: string): Observable<MaterialCutoff> {
    return this.store.select(selectMaterialCutoff(cutoffCode));
  }

  clearCache() {
    this.store.dispatch(MaterialCutoffActions.clearMaterialCutoffs());
  }
}
