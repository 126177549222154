import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NaooConstants } from '../../shared/NaooConstants';
import { AutocompleteService } from '../../shared/services/autocomplete/autocomplete.service';
import {
  MatAutocompleteModule,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { SearchConstants } from '../../shared/models/search/SearchConstants';
import { SearchFacade } from 'src/app/core/store/search/search.facade';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BoldPipe } from '../../shared/pipes/bold.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'naoo-search-bar',
  standalone: true,
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    BoldPipe,
    TranslateModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { subscriptSizing: 'dynamic' },
    },
  ],
})
export class SearchBarComponent implements OnInit, AfterViewInit {
  @ViewChild('searchText', { static: true }) searchBox: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { static: true })
  autocomplete: MatAutocompleteTrigger;
  @Input() isMobile: boolean;
  @Input() isDisabled: boolean;
  @Output() closeMobileSearchModalEmitter = new EventEmitter<boolean>();
  protected readonly MAX_INPUT_LENGTH = NaooConstants.MAX_INPUT_LENGTH;
  protected ariaText = signal<string>('');
  protected placeholderText = signal<string>('');
  inputText = signal<string>('');
  options = signal<string[]>([]);
  hideSearchText = signal<boolean>(false);

  constructor(
    private readonly autocompleteService: AutocompleteService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly searchFacade: SearchFacade,
  ) {}

  ngOnInit(): void {
    this.updateAriaLabelAndPlaceholderText();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.hideSearchText.set(
        params[SearchConstants.paramHideSearchText] !== undefined,
      );
    });
  }

  performSearch(searchText: string) {
    if (searchText?.trim()) {
      searchText = searchText.trim();
      if (searchText.length > NaooConstants.MAX_INPUT_LENGTH) {
        searchText = searchText.substring(0, NaooConstants.MAX_INPUT_LENGTH);
      }

      this.searchFacade.submitSearch(searchText);

      this.closeMobileSearchModalEmitter.emit(true);
      this.autocomplete.closePanel();
      this.clearAutocomplete();
    }
  }

  updateAriaLabelAndPlaceholderText() {
    // Screen-reader reads the placeholder text when the input is empty. If not it reads what
    // you wrote in the input, the aria-label attribute and the placeholder text. For that reason
    // we set the placeholder as empty when there is some text on the input.
    if (this.inputText() && this.inputText().trim().length > 0) {
      this.ariaText.set('SEARCH.TITLE');
      this.placeholderText.set('');
    } else {
      this.placeholderText.set(
        this.isMobile ? 'SEARCH.PLACEHOLDER_MOBILE' : 'SEARCH.PLACEHOLDER',
      );
      this.ariaText.set(this.placeholderText());
    }
  }

  getAutocompleteSuggestions(searchText: string) {
    if (searchText.length > 0) {
      this.autocompleteService
        .getAutocompleteSuggestions(searchText)
        .subscribe({
          next: (suggestions: string[]) => {
            this.options.set(suggestions);
          },
          error: () => {
            // do nothing
          },
        });
    } else {
      this.clearAutocomplete();
    }
  }

  ngAfterViewInit() {
    if (this.isMobile) {
      this.clearAndFocus();
    }
  }

  clear() {
    this.inputText.set('');
    this.updateAriaLabelAndPlaceholderText();
    this.clearAutocomplete();
  }

  clearAndFocus() {
    this.clear();
    (<HTMLInputElement>this.searchBox.nativeElement).focus();
  }

  closeModal() {
    this.closeMobileSearchModalEmitter.emit(true);
  }

  setText(text: string | null) {
    if (text && !this.hideSearchText()) {
      this.inputText.set(text);
    } else {
      this.inputText.set('');
    }
    this.updateAriaLabelAndPlaceholderText();
  }

  private clearAutocomplete() {
    this.options.set([]);
  }
}
