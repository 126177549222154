import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AvailableRouteDate,
  RouteDateContent,
} from '../../shared/models/delivery-date-picker';
import { IKeyboardEvent } from 'src/app/shared/utilities/keyboard-event-utilities';
import moment, { Moment } from 'moment';
import { MatCalendar } from '@angular/material/datepicker';

@Component({
  selector: 'naoo-delivery-date-picker-calendar',
  templateUrl: './delivery-date-picker-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCalendar],
})
export class DeliveryDatePickerCalendarComponent {
  @Input() routeDateContent: RouteDateContent;
  @Output() routeDateSelected = new EventEmitter<AvailableRouteDate>();
  @Output() shiftFocus = new EventEmitter<void>();

  selectedAvailableRouteDate?: AvailableRouteDate;

  onTabDown(event: IKeyboardEvent): boolean {
    if (event.target.tagName.toLowerCase() !== 'td') {
      return true;
    }

    this.shiftFocus.emit();
    return false;
  }

  get selectedDate(): Moment {
    return moment.utc(this.calculatedAvailableRouteDate.customerArrivalDate);
  }

  get calculatedAvailableRouteDate(): AvailableRouteDate {
    return (
      this.selectedAvailableRouteDate || this.routeDateContent.defaultRouteDate
    );
  }

  get startAtDate(): Moment {
    return moment.utc(
      this.routeDateContent.defaultRouteDate.customerArrivalDate,
    );
  }

  get calendarMinDate(): Moment {
    return moment.utc(
      this.routeDateContent.availableRouteDates[0].customerArrivalDate,
    );
  }

  get calendarMaxDate(): Moment {
    return moment.utc(
      this.routeDateContent.availableRouteDates[
        this.routeDateContent.availableRouteDates.length - 1
      ].customerArrivalDate,
    );
  }

  selectableRouteDateFilter = (calendarMoment: Moment): boolean => {
    const routeDateTimes = this.routeDateContent.availableRouteDates.map(
      (availableRouteDate) => {
        return availableRouteDate.customerArrivalDate?.getTime();
      },
    );
    return routeDateTimes.indexOf(calendarMoment.toDate().getTime()) !== -1;
  };

  onRouteDateSelected(selectedMoment: Moment) {
    const selectedAvailableRouteDate =
      this.routeDateContent.availableRouteDates.find((availableRouteDate) => {
        return (
          availableRouteDate.customerArrivalDate.getTime() ===
          selectedMoment.toDate().getTime()
        );
      });
    this.selectedAvailableRouteDate = selectedAvailableRouteDate;
    this.routeDateSelected.emit(selectedAvailableRouteDate);
    this.shiftFocus.emit();
  }
}
