import { createActionGroup, emptyProps } from '@ngrx/store';
import { MaterialCutoffRecord } from '../../services/material-cutoff/models/material-cutoff-record';

export const MaterialCutoffActions = createActionGroup({
  source: 'Material Cutoff',
  events: {
    'Load Material Cutoffs': (cutoffCodes?: string[]) => ({ cutoffCodes }),
    'Get Material Cutoffs': (cutoffCodes?: string[]) => ({ cutoffCodes }),
    'Get Material Cutoffs Success': (
      materialCutoffRecord: MaterialCutoffRecord,
    ) => ({ materialCutoffRecord }),
    'Get Material Cutoffs Error': (cutoffCodes?: string[]) => ({ cutoffCodes }),
    'Refresh Material Cutoffs': emptyProps(),
    'Clear Material Cutoffs': emptyProps(),
  },
});
