import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { MaterialCutoffRequest } from '../../services/material-cutoff/models/material-cutoff-request';
import { MaterialCutoffService } from '../../services/material-cutoff/material-cutoff.service';
import { MaterialCutoffActions } from './material-cutoff.actions';
import {
  selectAllMaterialCutoffRecords,
  selectMaterialCutoffEntities,
} from './material-cutoff.selectors';
import { selectFulfillmentType, selectRouteDate } from '../cart/cart.selectors';
import { selectCurrentOrFirstAvailableDeliverySchedule } from '../delivery-schedule/delivery-schedule.selectors';
import { formatDate } from '../../../shared/utilities/date-utilities';
import { SharedActions } from '../shared/shared.actions';
import { FulfillmentType } from '../../services/cart/models/cart-record';

@Injectable()
export class MaterialCutoffEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly materialCutoffService: MaterialCutoffService,
  ) {}

  loadMaterialCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.loadMaterialCutoffs),
      concatLatestFrom(() => this.store.select(selectMaterialCutoffEntities)),
      mergeMap(() => {
        return of(MaterialCutoffActions.getMaterialCutoffs());
      }),
    );
  });

  /**
   * Side effect of `GetMaterialCutoffsAction` results in making a service call to
   * get Material cutoff data. Must validate that there is a routeDate; Retalix
   * requests also require a routeId, and filter out previously stored data.
   * `GetMaterialCutoffsSuccessAction` will be dispatched and update the store
   * when the service call completes.
   * `GetMaterialCutoffsErrorAction` is dispatched on failure.
   */

  getCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.getMaterialCutoffs),
      concatLatestFrom(() => [
        this.store.select(selectFulfillmentType),
        this.store.select(selectRouteDate),
        this.store.select(selectCurrentOrFirstAvailableDeliverySchedule),
        this.store.select(selectMaterialCutoffEntities),
      ]),
      mergeMap(([_, fulfillmentType, routeDate, deliverySchedule]) => {
        if (FulfillmentType.TRUCK !== fulfillmentType || !deliverySchedule) {
          return of(
            SharedActions.noOperation(
              'Material cutoff times require a deliverySchedule',
            ),
          );
        }

        const formattedRouteDate = routeDate
          ? formatDate(routeDate)
          : undefined;
        const requestedRouteDate =
          formattedRouteDate || deliverySchedule.routeDate;

        const request: MaterialCutoffRequest = {
          routeDate: requestedRouteDate,
        };

        return this.materialCutoffService.getCutoffTimes(request).pipe(
          map((cutoffs) =>
            MaterialCutoffActions.getMaterialCutoffsSuccess(cutoffs),
          ),
          catchError(() =>
            of(
              MaterialCutoffActions.getMaterialCutoffsError(
                request.jitCutoffCodes,
              ),
            ),
          ),
          takeUntil(
            this.actions$.pipe(
              ofType(
                MaterialCutoffActions.refreshMaterialCutoffs,
                MaterialCutoffActions.clearMaterialCutoffs,
              ),
            ),
          ),
        );
      }),
    );
  });

  /**
   * Side effect of `RefreshMaterialCutoffsAction` clears the store Material cutoff data
   * and gets the previously requested cutoff codes using the new routeDate for Retalix
   * customers. MyGfs/SAP clears and requests for any cutoff code that is available for them.
   */

  refreshCutoffs$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(MaterialCutoffActions.refreshMaterialCutoffs),
      concatLatestFrom(() => this.store.select(selectAllMaterialCutoffRecords)),
      map(([_]) => {
        return MaterialCutoffActions.getMaterialCutoffs();
      }),
    );
  });
}
