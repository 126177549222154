<button
  class="select-order-method-button select-order-method-widget"
  [disabled]="disabled"
  (click)="openFulfillmentModal()"
  [ngClass]="{
    'normal-layout': !wideLayout,
    'wide-layout': wideLayout && !displayingDateOnly,
    'flex-column': !isFulfillmentTypeNone,
    disabled: disabled,
  }"
>
  <mat-icon
    class="calendar-image"
    [ngClass]="{ disabled: disabled }"
    [svgIcon]="icon"
  ></mat-icon>
  @if (this.isMobile) {
    @if (isFulfillmentTypeNone) {
      <div class="text flex-row">
        <div class="title">
          {{
            'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SHORT_TITLE' | translate
          }}
        </div>
        <div class="subtitle">
          {{
            'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SHORT_SUBTITLE' | translate
          }}
        </div>
      </div>
    } @else {
      <div class="fulfillment-text">
        <div class="fulfillment-title">
          @switch (this.viewModel?.fulfillmentType) {
            @case (FulfillmentType.TRUCK) {
              {{
                this.viewModel?.customerArrivalDate
                  | naooDate: 'monthDay' : '+0000'
              }}
            }
            @default {
              {{ this.viewModel?.routeDate | naooDate: 'monthDay' }}
            }
          }
        </div>
      </div>
    }
  } @else {
    <div
      class="text"
      [ngClass]="{ disabled: disabled, 'flex-row': isFulfillmentTypeNone }"
    >
      <div class="title">
        {{ title | translate }}
        @if (isFulfillmentTypeExpress) {
          <span>
            {{ this.viewModel?.routeDate | naooDate: 'monthDay' }}
          </span>
        }
      </div>
      <div class="subtitle">
        @switch (this.viewModel?.fulfillmentType) {
          @case (FulfillmentType.TRUCK) {
            {{
              this.viewModel?.customerArrivalDate
                | naooDate: 'monthDay' : '+0000'
            }}
          }
          @case (FulfillmentType.PICKUP) {
            {{ this.viewModel?.routeDate | naooDate: 'monthDay' : '+0000' }}
          }
          @case (FulfillmentType.EXPRESS) {
            {{
              this.viewModel?.deliveryWindowStartTimestamp
                | naooTimeRange
                  : this.viewModel?.deliveryWindowEndTimestamp
                  : this.viewModel?.customerTimeZone
            }}
          }
          @default {
            {{ 'SELECT_ORDER_METHOD.SELECT_ORDER_WIDGET_SUBTITLE' | translate }}
          }
        }
      </div>
    </div>
  }
</button>
