import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  combineLatest,
  fromEvent,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { CONTENT_SCROLL } from '../shared/services/scrollable-content/scrollable-content.service';
import {
  MaterialListStyle,
  MaterialRowContext,
  MaterialRowSize,
} from '../core/store/material-row/models/material-row';
import { MaterialListRow, MaterialListRowType } from './models/material-list';
import { SessionFacade } from '../core/store/session/session.facade';
import {
  IPageInfo,
  VirtualScrollerComponent,
} from '../vendor/ngx-virtual-scroller/virtual-scroller';
import { AuxiliaryAnalyticsData } from '../core/services/ecommerce-analytics/models/google-events';
import { MaterialListHeaderComponent } from './material-list-header/material-list-header.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { MaterialRowContainerComponent } from './material-row-container/material-row-container.component';
import { MaterialCategoryHeaderComponent } from './material-category-header/material-category-header.component';

@Component({
  selector: 'naoo-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MaterialListHeaderComponent,
    VirtualScrollerComponent,
    NgClass,
    MaterialRowContainerComponent,
    MaterialCategoryHeaderComponent,
    AsyncPipe,
  ],
})
export class MaterialListComponent implements OnInit, OnDestroy {
  @Input() materialListItems: MaterialListRow[];
  @Input() context: MaterialRowContext;

  @Input() set listStyle(listStyle: MaterialListStyle) {
    this._listStyle = listStyle;
    this.listStyleUpdates$.next(listStyle);
  }

  get listStyle(): MaterialListStyle {
    return this._listStyle;
  }

  @Input() shouldRenderPrint = false;
  @Input() customGuideId?: string;
  @Input() isParEnabled: boolean;
  @Input() displayGoPointsLogo: boolean = true;
  @Input() analytics?: AuxiliaryAnalyticsData;

  @Output() endOfList: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('rowContainers', { static: true })
  rowContainers: ElementRef;

  @ViewChild('scroll')
  virtualScroll: VirtualScrollerComponent<MaterialListRow>;

  isMobile: boolean;
  rowHeight$: Observable<number>;
  virtualScrollBuffer$: Observable<number>;
  firstItemMargin$: Observable<number>;
  isLoyaltyProgramEligible$: Observable<boolean> = this.sessionFacade
    .getLoadedActiveCustomer()
    .pipe(map((customer) => customer?.isLoyaltyProgramEligible));

  readonly virtualScrollAnimationTime = 0;
  readonly materialRowType = MaterialListRowType.MaterialRow;

  private readonly destroyed$ = new Subject<boolean>();
  private readonly listStyleUpdates$ = new ReplaySubject<MaterialListStyle>(1);
  private _listStyle: MaterialListStyle;

  private readonly minimumRowWidth = 702;
  private readonly minimumViewportWidth = 1111;
  private readonly resizeEventDebounceTime = 100;

  // These two row heights not placed inside the Record are specific to cart style rows,
  // any changes to the general sizing of List style cards should be reflected not only in the
  // record but these properties as well
  private readonly desktopRowHeightWithoutFooter = 92;
  private readonly mobileRowHeightWithoutFooter = 223;
  private readonly rowHeights: Record<
    MaterialListStyle,
    Record<MaterialRowSize, number>
  > = {
    SlimGrid: {
      Mobile: 314,
      Desktop: 314,
    },
    Grid: {
      Mobile: 514,
      Desktop: 514,
    },
    Slim: {
      Mobile: 178,
      Desktop: 54,
    },
    List: {
      Mobile: 261,
      Desktop: 127,
    },
  };

  /*
   Here we use smaller buffer sizes on mobile to reduce the DOM load on the device. The Material
   Tab component has a costly size calculation that can negatively impact scrolling performance,
   so the fewer rows we render the more performant the list will be.
  */
  private readonly virtualScrollBuffers: Record<
    MaterialListStyle,
    Record<MaterialRowSize, number>
  > = {
    SlimGrid: {
      Mobile: 8,
      Desktop: 10,
    },
    Grid: {
      Mobile: 5,
      Desktop: 5,
    },
    Slim: {
      Mobile: 8,
      Desktop: 40,
    },
    List: {
      Mobile: 3,
      Desktop: 15,
    },
  };

  /*
   * Reference the material-category-header styles for these values when making changes
   */
  private readonly virtualScrollCompensatingMargin: Record<
    MaterialListStyle,
    Record<MaterialRowSize, number>
  > = {
    List: {
      Mobile: -186,
      Desktop: -52,
    },
    Slim: {
      Mobile: -138,
      Desktop: -14,
    },
    Grid: {
      Mobile: 0,
      Desktop: 0,
    },
    SlimGrid: {
      Mobile: 0,
      Desktop: 0,
    },
  };

  constructor(
    private readonly _window: Window,
    private readonly sessionFacade: SessionFacade,
    private readonly changeDetector: ChangeDetectorRef,
    @Inject(CONTENT_SCROLL) public parentScrollElement: Element,
  ) {}

  ngOnInit(): void {
    this.listStyleUpdates$.next(this.listStyle);

    setTimeout(() => {
      const isMobile$ = fromEvent(window, 'resize').pipe(
        debounceTime(this.resizeEventDebounceTime),
        startWith(undefined),
        map(() =>
          this.getIsMobile(
            this.rowContainers.nativeElement.offsetWidth,
            this._window.innerWidth,
          ),
        ),
        distinctUntilChanged(),
        shareReplay(1),
        takeUntil(this.destroyed$),
      );

      this.rowHeight$ = combineLatest([isMobile$, this.listStyleUpdates$]).pipe(
        map(([isMobile, listStyle]) =>
          this.getRowHeight(
            isMobile ? MaterialRowSize.Mobile : MaterialRowSize.Desktop,
            listStyle,
          ),
        ),
      );

      this.virtualScrollBuffer$ = combineLatest([
        isMobile$,
        this.listStyleUpdates$,
      ]).pipe(
        map(
          ([isMobile, listStyle]) =>
            this.virtualScrollBuffers[listStyle][
              isMobile ? MaterialRowSize.Mobile : MaterialRowSize.Desktop
            ],
        ),
      );

      this.firstItemMargin$ = combineLatest([
        isMobile$,
        this.listStyleUpdates$,
      ]).pipe(
        map(([isMobile, listStyle]) =>
          this.firstItemMargin(
            isMobile ? MaterialRowSize.Mobile : MaterialRowSize.Desktop,
            listStyle,
          ),
        ),
      );

      isMobile$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        this.changeDetector.detectChanges();
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  scrollToIndex(vsIndex: number): void {
    this.virtualScroll.scrollToIndex(vsIndex);
  }

  vsEnd(event: IPageInfo): void {
    if (
      event.endIndexWithBuffer >= this.materialListItems?.length - 1 &&
      this.materialListItems?.length > 0
    ) {
      this.endOfList.emit(true);
    }
  }

  get parentScroll(): Element | undefined {
    if (
      this.isMobile &&
      [MaterialRowContext.Substitutes].includes(this.context)
    ) {
      return undefined;
    }
    return this.parentScrollElement;
  }

  get shouldRenderHeader(): boolean {
    return (
      !this.isGridView &&
      !this.isGuideContextListStyle &&
      !this.isRecommendation
    );
  }

  get isGridView(): boolean {
    return this.listStyle === MaterialListStyle.Grid;
  }

  get isSlimView(): boolean {
    return this.listStyle === MaterialListStyle.Slim;
  }

  get isRecommendation(): boolean {
    return [MaterialRowContext.Recommendation].includes(this.context);
  }

  get isGuideContextListStyle(): boolean {
    return (
      [
        MaterialRowContext.OrderGuide,
        MaterialRowContext.CriticalItemsGuide,
        MaterialRowContext.CustomGuide,
        MaterialRowContext.MarketingGuide,
      ].includes(this.context) && this.listStyle === MaterialListStyle.List
    );
  }

  /**
   * Combine the 'analytics' in the component with the 'searchResultsIndex' within the 'materialListItem'
   * because we're not expecting 'searchResultsIndex' to be populated on 'analytics' for this component.
   *
   * @param materialListItem the current MaterialListRow object
   */
  getAnalytics(materialListItem: MaterialListRow): AuxiliaryAnalyticsData {
    return {
      ...this.analytics,
      searchResultsIndex: materialListItem.searchResultsIndex,
    };
  }

  /**
   * Row headers have negative margin to visually compensate for being the same size as a standard
   * row. This helps virtual scroller avoid "jumps".
   */
  private firstItemMargin(
    rowSize: MaterialRowSize,
    listStyle: MaterialListStyle,
  ): number {
    if (
      this.materialListItems?.[0]?.type === MaterialListRowType.CategoryHeader
    ) {
      return this.virtualScrollCompensatingMargin[listStyle][rowSize];
    }
    return 0;
  }

  private isCartContextListStyle(): boolean {
    return (
      [
        MaterialRowContext.CartReview,
        MaterialRowContext.CartSummary,
        MaterialRowContext.CriticalItem,
        MaterialRowContext.Substitutes,
        MaterialRowContext.NonEditableItem,
        MaterialRowContext.PdpLinkItem,
        MaterialRowContext.ErrorProcessingItem,
      ].includes(this.context) && this.listStyle === MaterialListStyle.List
    );
  }

  private getIsMobile(rowSize: number, viewPortWidth: number): boolean {
    return !(
      rowSize > this.minimumRowWidth &&
      viewPortWidth > this.minimumViewportWidth
    );
  }

  private getRowHeight(
    rowSize: MaterialRowSize,
    listStyle: MaterialListStyle,
  ): number {
    if (this.isCartContextListStyle()) {
      return rowSize === MaterialRowSize.Mobile
        ? this.mobileRowHeightWithoutFooter
        : this.desktopRowHeightWithoutFooter;
    }

    return this.rowHeights[listStyle][rowSize];
  }
}
