import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderItem } from '../shared/models/order-confirmation';
import { NaooAnalyticsManager } from '../../shared/analytics/NaooAnalyticsManager';
import {
  MaterialRow,
  MaterialRowContext,
} from '../../core/store/material-row/models/material-row';
import { MaterialRecommendationsFacade } from '../../core/store/material-recommendations/material-recommendations.facade';
import { Observable, Subject } from 'rxjs';
import { MaterialRecommendations } from '../../shared/services/material-recommendations/models/material-recommendations';
import { takeUntil } from 'rxjs/operators';
import { MaterialRowFacade } from '../../core/store/material-row/material-row.facade';
import { Page } from '../../core/store/material-recommendations/material-recommendations.state';
import { MaterialRelatedContentComponent } from './material-related-content/material-related-content.component';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'naoo-material-related-container',
  template: `
    <naoo-material-related-content
      [itemException]="data.itemException"
      [materialWithSubstitutes]="materialWithSubstitutes"
      [isLoading]="materialRecommendationsLoading$ | async"
      [isMobile]="data.isMobile"
      [materialRecommendations]="materialRecommendations$ | async"
      (closeButtonClicked)="closeModal()"
    >
    </naoo-material-related-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MaterialRelatedContentComponent, AsyncPipe],
})
export class MaterialRelatedContainerComponent implements OnInit, OnDestroy {
  private readonly openAnalyticsLabel = 'open find substitutes modal';
  private readonly closeAnalyticsLabel = 'close find substitutes modal';

  private readonly destroyed$ = new Subject<void>();
  private materialRow: MaterialRow;

  materialRecommendations$: Observable<MaterialRecommendations[]>;
  materialRecommendationsLoading$: Observable<boolean>;

  /**
   * @deprecated remove in NAOO-31456 when Retalix is no longer supported
   */
  materialRelatedDataLoaded: boolean;

  constructor(
    public dialogRef: MatDialogRef<MaterialRelatedContainerComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      itemException: OrderItem;
      materialWithSubstitutes: MaterialRow;
      isMobile: boolean;
    },
    private readonly analytics: NaooAnalyticsManager,
    private readonly materialRecommendationsFacade: MaterialRecommendationsFacade,
    private readonly materialRowFacade: MaterialRowFacade,
  ) {}

  ngOnInit(): void {
    this.trackAnalytics(this.openAnalyticsLabel);
    this.init();

    if (!this.data.materialWithSubstitutes) {
      this.materialRowFacade
        .getMaterialRow({
          context: MaterialRowContext.Substitutes,
          materialNumber: this.materialNumber,
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe(
          (materialRow: MaterialRow) => (this.materialRow = materialRow),
        );
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  closeModal(): void {
    this.trackAnalytics(this.closeAnalyticsLabel);
    this.dialogRef.close();
  }

  get materialNumber(): string {
    if (this.data.itemException) {
      return this.data.itemException.itemDetail.id;
    } else {
      return this.data.materialWithSubstitutes.materialRowOptions
        .materialNumber;
    }
  }

  get materialWithSubstitutes(): MaterialRow {
    return this.data.materialWithSubstitutes || this.materialRow;
  }

  private init(): void {
    this.materialRecommendationsFacade.loadMaterialRecommendations(
      Page.RELATED,
      this.materialNumber,
    );
    this.materialRecommendations$ =
      this.materialRecommendationsFacade.getLoadedMaterialRecommendations(
        Page.RELATED,
        true,
      );
    this.materialRecommendationsLoading$ =
      this.materialRecommendationsFacade.isMaterialRecommendationsLoading(
        Page.RELATED,
      );
  }

  private trackAnalytics(labelPrefix: string): void {
    this.analytics.trackAnalyticsEvent({
      action: 'click',
      category: 'Ecommerce',
      label: `${labelPrefix} - ${this.materialNumber}`,
    });
  }
}
