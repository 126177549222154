import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class KetchService {
  private readonly scriptUrl =
    'https://global.ketchcdn.com/web/v2/config/gfs/us_distribution/boot.js';

  constructor(@Inject(DOCUMENT) private readonly _document: Document) {}

  removeKetchScript(window: Window): void {
    const existingScript = this._document.querySelector(
      `script[src*="ketchcdn.com"]`,
    );
    if (existingScript) {
      existingScript.remove();
    }
    delete window.ketch;
    delete window.semaphore;
  }

  attachKetchScript(window: Window): void {
    window.semaphore = window.semaphore || [];
    window.ketch = function () {
      window.semaphore.push(arguments);
    };

    const ketchScript = this._document.createElement('script');
    ketchScript.type = 'text/javascript';
    ketchScript.src = this.scriptUrl;
    ketchScript.defer = ketchScript.async = true;

    this._document.head.appendChild(ketchScript);
  }

  resetKetchScript(window: Window): void {
    this.removeKetchScript(window);
    this.attachKetchScript(window);
  }
}
