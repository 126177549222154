import { createSelector } from '@ngrx/store';
import { selectActiveStoreFulfillment } from '../store/store.selectors';
import { OrderMethodWidgetViewModel } from './order-method-widget-view-model';
import moment, { Moment } from 'moment';
import { FulfillmentType } from '../../services/cart/models/cart-record';
import { selectActiveCustomerTimezone } from '../session/session.selectors';
import { selectCustomerArrivalDate } from '../cart/cart.selectors';

export const selectOrderMethodWidgetViewModel = createSelector(
  selectActiveStoreFulfillment,
  selectCustomerArrivalDate,
  selectActiveCustomerTimezone,
  (
    activeStoreFulfillment,
    cachedCustomerArrivalDate,
    customerTimeZone,
  ): OrderMethodWidgetViewModel => {
    let routeDate: Moment;
    let customerArrivalDate: Date;
    let fulfillmentType: FulfillmentType = FulfillmentType.NONE;
    let deliveryWindowStartTimestamp: string;
    let deliveryWindowEndTimestamp: string;
    let selectedStoreName: string;
    const expressWindow = activeStoreFulfillment?.storeFulfillment;
    if (expressWindow) {
      fulfillmentType = FulfillmentType.EXPRESS;
      deliveryWindowStartTimestamp = expressWindow.deliveryWindowStartTimestamp;
      deliveryWindowEndTimestamp = expressWindow.deliveryWindowEndTimestamp;
      routeDate = moment(deliveryWindowStartTimestamp);
    } else if (activeStoreFulfillment?.store?.name) {
      fulfillmentType = FulfillmentType.PICKUP;
      routeDate = activeStoreFulfillment.selectedDate;
      selectedStoreName = activeStoreFulfillment.store.name;
    } else if (cachedCustomerArrivalDate) {
      fulfillmentType = FulfillmentType.TRUCK;
      customerArrivalDate = cachedCustomerArrivalDate;
    }

    return {
      fulfillmentType,
      customerTimeZone,
      routeDate,
      customerArrivalDate,
      deliveryWindowStartTimestamp,
      deliveryWindowEndTimestamp,
      selectedStoreName,
    };
  },
);
