import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { SessionService } from '../../services/session/session.service';
import { defaultIfEmpty, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  catchError,
  delayWhen,
  first,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ErrorActions } from '../error/error.actions';
import { TranslateService } from '@ngx-translate/core';
import * as LogRocket from 'logrocket';
import { NaooAnalyticsManager } from '../../../shared/analytics/NaooAnalyticsManager';
import { NaooLogger } from '../../../shared/logger/NaooLogger.service';
import { NotificationService } from '../../../shared/services/notification/notification.service';
import { MaterialInfoActions } from '../material-info/material-info.actions';
import { MaterialCutoffActions } from '../material-cutoff/material-cutoff.actions';
import { DeliveryScheduleActions } from '../delivery-schedule/delivery-schedule.actions';
import { MaterialValidationService } from '../../../shared/services/material-validation/material-validation.service';
import {
  selectActiveCustomer,
  selectGordonNowData,
  selectLanguage,
  selectPreviousCompositeCustomerId,
} from './session.selectors';
import { SessionRecord } from '../../services/session/models/session-record';
import { OrderGuideChangeHistoryActions } from '../order-guide-change-history/order-guide-change-history.actions';
import { CustomerMaterialActions } from '../customer-material/customer-material.actions';
import { MessageService } from '../../../shared/services/message/message.service';
import { CustomerPreferencesActions } from '../customer-preferences/customer-preferences.actions';
import { CatalogService } from '../../../shared/services/catalog/catalog.service';
import { MaterialWarningActions } from '../material-warning/material-warning.actions';
import { MaterialAdditionalInfoActions } from '../material-additional-info/material-additional-info.actions';
import { CartActions } from '../cart/cart.actions';
import { CustomerNotificationPreferencesActions } from '../customer-notification-preferences/customer-notification-preferences.actions';
import { CriticalItemsActions } from '../critical-items/critical-items.actions';
import { OpenOrderItemsActions } from '../open-order-items/open-order-items.actions';
import { MaterialComparisonActions } from '../material-comparison/material-comparison.actions';
import { LastOrderedActions } from '../last-ordered/last-ordered.actions';
import { MaterialAvailabilityActions } from '../material-availability/material-availability.actions';
import { MaterialRowActions } from '../material-row/material-row.actions';
import { BannerAdsActions } from '../banner-ads/banner-ads.actions';
import { MultipleCartsActions } from '../multiple-carts/multiple-carts.actions';
import { MaterialUnitsActions } from '../material-units/material-units.actions';
import { CustomGuideActions } from '../custom-guide/custom-guide.actions';
import { EcommerceAnalyticsActions } from '../ecommerce-analytics/ecommerce-analytics.actions';
import { EntitlementActions } from '../entitlement/entitlement.actions';
import { CommodityPriceActions } from '../commodity-price/commodity-price.actions';
import { MaterialPriceActions } from '../material-price/material-price.actions';
import { FacebookEventType } from '../../../shared/analytics/facebook-tracking/facebook-events';
import { IndicatorListsActions } from '../indicator-lists/indicator-lists.actions';
import { GordonNowChatService } from '../../../shared/gordon-now/gordon-now-chat.service';
import { ExpressSchedulesActions } from '../express-schedules/express-schedules.actions';
import { ActiveCartSummaryActions } from '../active-cart/active-cart-summary.actions';
import { PickupSchedulesActions } from '../pickup-schedules/pickup-schedules.actions';
import { InventoryAvailabilityActions } from '../inventory-availability/inventory-availability.actions';
import { SalesCriticalItemsActions } from '../sales-critical-items/sales-critical-items.actions';
import { SearchActions } from '../search/search.actions';
import { SessionActions } from './session.actions';
import { SharedActions } from '../shared/shared.actions';
import { SpecialOrdersActions } from '../special-orders/special-orders.actions';
import { StoreActions } from '../store/store.actions';
import { OrderGuideActions } from '../order-guide/order-guide.actions';
import { StorePurchaseHistoryActions } from '../store-purchase-history/store-purchase-history.actions';
import { StorePurchaseDetailsActions } from '../store-purchase-details/store-purchase-details.actions';
import { MaterialRecommendationsActions } from '../material-recommendations/material-recommendations.actions';
import { SmartCartRecommendationsActions } from '../smart-cart-recommendations/smart-cart-recommendations.actions';
import { NaooSessionStorage } from '../../../shared/storage/session-storage/session-storage.service';
import { KetchService } from '../../../ketch/ketch.service';

@Injectable()
export class SessionEffects {
  private static readonly ANALYTICS_INDEX_VALUE = 36;

  // eslint-disable-next-line max-params
  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly sessionService: SessionService,
    private readonly translateService: TranslateService,
    private readonly _window: Window,
    private readonly analyticsManager: NaooAnalyticsManager,
    private readonly naooLogger: NaooLogger,
    private readonly notificationService: NotificationService,
    private readonly productValidationService: MaterialValidationService,
    private readonly messageService: MessageService,
    private readonly catalogService: CatalogService,
    private readonly gnChatService: GordonNowChatService,
    private readonly sessionStorage: NaooSessionStorage,
    private readonly ketchService: KetchService,
  ) {}

  getSession$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.getSession),
      mergeMap(() => {
        return this.sessionService.getSession().pipe(
          map((sessionRecord: SessionRecord) => {
            this.ketchService.attachKetchScript(this._window);
            return SessionActions.getSessionSuccess(sessionRecord);
          }),
          catchError((error) => of(ErrorActions.fatalError(error))),
        );
      }),
    );
  });

  updateSession$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.updateSession),
      mergeMap((action) => {
        return this.sessionService
          .updateSession(action.updateSessionRequest)
          .pipe(
            mergeMap((sessionRecord: SessionRecord) => {
              const actions: Action[] = [
                SessionActions.updateSessionSuccess(sessionRecord),
              ];
              if (action.updateSessionRequest.isLanguageChange) {
                actions.push(
                  BannerAdsActions.refreshBannerAds(),
                  SessionActions.updateGordonNowChat(),
                );
              }
              return actions;
            }),
            catchError((error) => of(ErrorActions.fatalError(error))),
          );
      }),
    );
  });

  getOrUpdateSessionSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SessionActions.getSessionSuccess,
        SessionActions.updateSessionSuccess,
      ),
      concatLatestFrom(() => [
        this.store.select(selectPreviousCompositeCustomerId),
        this.store.select(selectActiveCustomer),
      ]),
      switchMap(
        ([
          action /* GetSessionSuccessAction | UpdateSessionSuccessAction */,
          previousCompositeCustomerId /* string */,
          customer /* Customer */,
        ]) => {
          const sessionRecord = action.sessionRecord;
          if (!sessionRecord) {
            return [SharedActions.noOperation('Session record undefined')];
          }

          const shouldUpdateSession =
            previousCompositeCustomerId !==
            sessionRecord.activeCustomer?.naooCustomerId;

          if (
            this.translateService.getLangs().includes(sessionRecord.language)
          ) {
            this.translateService.use(sessionRecord.language);
          }

          if (sessionRecord.activeCustomer) {
            LogRocket.track('CustomerSelected', {
              customerId: customer.customerDisplayId,
            });
          }

          if (shouldUpdateSession) {
            if (sessionRecord.email) {
              LogRocket.identify(sessionRecord.email);
              this.identifyDynatraceUser(sessionRecord.email);
            }
            if (sessionRecord.isPunchOut) {
              this.analyticsManager.pushCustomDimension({
                index: SessionEffects.ANALYTICS_INDEX_VALUE,
                value: 'true',
              });
            }
            const actions: Action[] = [
              EcommerceAnalyticsActions.googleCustomerLoginEvent(true),
            ];
            if (sessionRecord.activeCustomer) {
              actions.push(
                SessionActions.refreshCustomerData(
                  customer.customerDisplayId,
                  customer.naooCustomerId,
                  customer.plantNumber,
                ),
                SessionActions.updateGordonNowChat(),
              );
              this.saveIdsToSessionStorageAndResetKetchScript(sessionRecord);
            }
            return actions;
          } else {
            this.ketchService.attachKetchScript(this._window);
          }
          return [
            SharedActions.noOperation(
              'Same Customer ID selected - NOT refreshing customer data.',
            ),
          ];
        },
      ),
    );
  });

  updateSelectedCustomer$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.updateSelectedCustomer),
      concatLatestFrom(() => this.store.select(selectLanguage)),
      map(([action, language]) => {
        return SessionActions.updateSession({
          language: language,
          naooCustomerId: action.compositeCustomerId,
        });
      }),
    );
  });

  updateSelectedLanguage$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.updateSelectedLanguage),
      map((action) => {
        if (
          action.language &&
          this.translateService.getLangs().includes(action.language)
        ) {
          this.translateService.use(action.language);

          return SessionActions.updateSession({
            language: action.language,
            isLanguageChange: true,
          });
        }

        return SharedActions.noOperation('Selected language is invalid.');
      }),
    );
  });

  refreshCustomerData$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(SessionActions.refreshCustomerData),
      mergeMap((action) => {
        this.analyticsManager.pushCustomDimension({
          index: 4,
          value: action.customerId,
        });
        this.analyticsManager.pushCustomDimension({
          index: 62,
          value: action.plantNumber,
        });
        this.notificationService.clearAllNotifications();
        this.productValidationService.clearCache();
        this.catalogService.clearCache();
        this.messageService.loadMessages();

        const isTransfer = this._window.location.href.includes('transfer');

        return [
          CustomerNotificationPreferencesActions.clearCustomerNotifications(),
          InventoryAvailabilityActions.clearInventoryAvailability(),
          LastOrderedActions.clearLastOrdered(),
          MaterialAdditionalInfoActions.clearMaterialAdditionalInfo(),
          MaterialAvailabilityActions.clearMaterialAvailability(),
          MaterialCutoffActions.clearMaterialCutoffs(),
          MaterialInfoActions.clearMaterialInfo(),
          EcommerceAnalyticsActions.clearMaterialRecommendations(),
          MaterialWarningActions.clearMaterialWarning(),
          OpenOrderItemsActions.clearOpenOrderItems(),
          MaterialPriceActions.clearMaterialPrices(),
          CommodityPriceActions.clearCommodityPrices(),
          PickupSchedulesActions.clearPickupSchedules(),
          SearchActions.clearSearchState(),
          MaterialComparisonActions.hydrateMaterialComparison(),
          MaterialRowActions.hydratePreferredMaterialView(),
          CustomGuideActions.refreshAllCustomGuides(),
          BannerAdsActions.refreshBannerAds(),
          CartActions.refreshCart(!isTransfer),
          ActiveCartSummaryActions.refreshCartSort(),
          CriticalItemsActions.refreshCriticalItems(),
          CustomerMaterialActions.refreshCustomerMaterialCodes(),
          CustomerPreferencesActions.refreshCustomerPreferences(),
          DeliveryScheduleActions.refreshDeliverySchedules(),
          EntitlementActions.clearEntitlement(),
          MaterialUnitsActions.refreshMaterialUnitsInformation(),
          OrderGuideChangeHistoryActions.refreshOrderGuideChangeHistory(),
          OrderGuideActions.refreshOrderGuide(),
          SalesCriticalItemsActions.refreshSalesCriticalItems(),
          MultipleCartsActions.refreshSavedCarts(true),
          SpecialOrdersActions.refreshSpecialOrders(),
          StoreActions.refreshStores(),
          ExpressSchedulesActions.refreshExpressSchedules(),
          CartActions.syncOfflineCart(),
          EcommerceAnalyticsActions.sendFacebookConversions(
            FacebookEventType.PageView,
          ),
          IndicatorListsActions.refreshIndicatorLists(),
          StorePurchaseHistoryActions.clearStorePurchaseHistory(),
          StorePurchaseDetailsActions.clearStorePurchaseDetails(),
          MaterialRecommendationsActions.clearMaterialRecommendations(),
          CustomerNotificationPreferencesActions.refreshCustomerNotificationPreferences(),
          SmartCartRecommendationsActions.clearSmartCartRecommendations(),
          SessionActions.refreshCustomerDataSuccess(action.compositeCustomerId), // Should always be last
        ];
      }),
    );
  });

  updateGordonNowChat$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SessionActions.updateGordonNowChat),
        delayWhen(() =>
          this.store.select(selectGordonNowData).pipe(
            first((gordonNowData) => !!gordonNowData),
            takeUntil(
              this.actions$.pipe(
                ofType(
                  SessionActions.getSessionSuccess,
                  SessionActions.updateSessionSuccess,
                ),
              ),
            ),
            defaultIfEmpty(true),
          ),
        ),
        concatLatestFrom(() => this.store.select(selectGordonNowData)),
        tap(([_, gnData]) => this.gnChatService.updateState(gnData)),
      );
    },
    {
      dispatch: false,
    },
  );

  private identifyDynatraceUser(email: string) {
    if (this._window.dtrum !== undefined) {
      try {
        this._window.dtrum.identifyUser(email);
      } catch (error) {
        this.naooLogger.error(
          'GFS-BgECAwcCCQ4DAAkFBAkMBg Failed to identify with Dynatrace',
          error,
        );
      }
    }
  }

  private saveIdsToSessionStorageAndResetKetchScript(
    sessionRecord: SessionRecord,
  ) {
    this.sessionStorage.setItem('userGuid', sessionRecord.guid);
    this.sessionStorage.setItem(
      'customerId',
      sessionRecord.activeCustomer?.fourPartKey?.customerId,
    );
    this.ketchService.resetKetchScript(this._window);
  }
}
