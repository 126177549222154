import { MaterialCutoffActions } from './material-cutoff.actions';
import {
  initialMaterialCutoffState,
  materialCutoffAdapter,
  MaterialCutoffRecordState,
  MaterialCutoffRecordStatus,
  MaterialCutoffState,
} from './material-cutoff.state';
import { createReducer, on } from '@ngrx/store';
import { MaterialCutoffRecord } from '../../services/material-cutoff/models/material-cutoff-record';

export const materialCutoffReducer = createReducer(
  initialMaterialCutoffState,
  on(MaterialCutoffActions.loadMaterialCutoffs, () => loadMaterialCutoffs()),
  on(MaterialCutoffActions.getMaterialCutoffs, (state) =>
    getMaterialCutoffs(state),
  ),
  on(MaterialCutoffActions.getMaterialCutoffsSuccess, (state, action) =>
    getMaterialCutoffsSuccess(state, action),
  ),
  on(MaterialCutoffActions.getMaterialCutoffsError, (state) =>
    getMaterialCutoffsError(state),
  ),
  on(MaterialCutoffActions.refreshMaterialCutoffs, () =>
    refreshMaterialCutoffs(),
  ),
  on(MaterialCutoffActions.clearMaterialCutoffs, () => clearMaterialCutoffs()),
);

function loadMaterialCutoffs() {
  return initialMaterialCutoffState;
}

function getMaterialCutoffs(state: MaterialCutoffState) {
  return state;
}

function getMaterialCutoffsSuccess(
  state: MaterialCutoffState,
  action: {
    materialCutoffRecord: MaterialCutoffRecord;
  },
) {
  const upsertedCutoffRecords: MaterialCutoffRecordState[] =
    action.materialCutoffRecord.cutoffs.map((record) => ({
      cutoffCode: record.cutoffCode,
      status: MaterialCutoffRecordStatus.Success,
      record: record,
    }));

  const loadedAllCutoffs = true;
  return {
    ...state,
    cutoffs: materialCutoffAdapter.upsertMany(
      upsertedCutoffRecords,
      state.cutoffs,
    ),
    hasLoadedAllCutoffs: loadedAllCutoffs,
    considerInventory: action.materialCutoffRecord.considerInventory,
  };
}

function getMaterialCutoffsError(state: MaterialCutoffState) {
  return {
    ...state,
    hasLoadedAllCutoffs: true,
    considerInventory: false,
  };
}

function refreshMaterialCutoffs() {
  return initialMaterialCutoffState;
}

function clearMaterialCutoffs() {
  return initialMaterialCutoffState;
}
