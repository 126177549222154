import { createSelector } from '@ngrx/store';
import { NaooConstants } from '../../../shared/NaooConstants';
import { sortBy } from 'lodash-es';
import {
  selectAllCustomGuideInfoRecords,
  selectHasCustomGuidesLoaded,
} from './custom-guide.selectors';
import { GenericMenuViewModel } from '../../../shared/generic-menu/generic-menu.model';
import { GenericNavigationElement } from '../../../shared/generic-navigation-modal/generic-navigation-model';

const menuGuidesTitle = 'MENU.GUIDES';

export const selectGuidesDesktopMenu = createSelector(
  selectAllCustomGuideInfoRecords,
  selectHasCustomGuidesLoaded,
  (customGuidesInfo, loaded): GenericMenuViewModel => ({
    title: menuGuidesTitle,
    ariaLabel: menuGuidesTitle,
    topLevel: true,
    icon: NaooConstants.icons.guides_menu_icon.name,
    menuElements: [
      {
        name: 'MENU.ORDER_GUIDE',
        route: NaooConstants.ORDER_GUIDE_PATH,
        isViewAll: false,
      },
      {
        name: 'LISTS.CUSTOM_GUIDE',
        isViewAll: false,
        route:
          loaded && customGuidesInfo?.length > 0
            ? undefined
            : NaooConstants.CUSTOM_GUIDE_PATH,
        menuElements: sortBy(customGuidesInfo, (customGuide) =>
          customGuide.name.toLowerCase(),
        ).map((customGuide) => ({
          name: {
            es: customGuide.name,
            en: customGuide.name,
            fr: customGuide.name,
          },
          route: `${NaooConstants.CUSTOM_GUIDE_PATH}/${customGuide.id}`,
          isViewAll: false,
        })),
      },
      {
        name: 'CRITICAL_ITEMS.TITLE',
        route: NaooConstants.CRITICAL_ITEMS_GUIDE_PATH,
        isViewAll: false,
      },
      {
        name: undefined,
        route: NaooConstants.GUIDES_PATH,
        isViewAll: true,
      },
    ],
    maxDisplayed: 9,
  }),
);

export const selectGuidesMobileMenu = createSelector(
  selectAllCustomGuideInfoRecords,
  selectHasCustomGuidesLoaded,
  (customGuidesInfo, loaded): GenericNavigationElement => ({
    name: menuGuidesTitle,
    showBackNavigation: true,
    viewAllRoute: NaooConstants.GUIDES_PATH,
    childNavigation: [
      {
        name: 'LISTS.CRITICAL_ITEMS_GUIDE',
        route: NaooConstants.CRITICAL_ITEMS_GUIDE_PATH,
      },
      {
        name: 'LISTS.ORDER_GUIDE',
        route: NaooConstants.ORDER_GUIDE_PATH,
      },
      {
        name: 'LISTS.CUSTOM_GUIDE',
        showBackNavigation: true,
        route:
          loaded && customGuidesInfo?.length
            ? undefined
            : NaooConstants.CUSTOM_GUIDE_PATH,
        childNavigation: customGuidesInfo.map(
          (customGuide): GenericNavigationElement => ({
            name: customGuide.name,
            route: `${NaooConstants.CUSTOM_GUIDE_PATH}/${customGuide.id}`,
          }),
        ),
      },
    ],
  }),
);
