import { EMPTY, Observable } from 'rxjs';
import { GenericMenuViewModel } from '../../../shared/generic-menu/generic-menu.model';
import {
  selectGuidesDesktopMenu,
  selectGuidesMobileMenu,
} from './guides-menu.selectors';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CatalogService } from '../../../shared/services/catalog/catalog.service';
import { filter, map, switchMap } from 'rxjs/operators';
import { NaooConstants } from '../../../shared/NaooConstants';
import { SessionFacade } from '../session/session.facade';
import { FeatureFlag } from '../../services/session/models/session-record';
import { GenericNavigationElement } from '../../../shared/generic-navigation-modal/generic-navigation-model';

@Injectable({ providedIn: 'root' })
export class GuidesMenuFacade {
  private readonly rootCategoryKey: string = '0';
  private readonly menuCategoriesTitle = 'MENU.CATEGORIES';

  constructor(
    private readonly store: Store,
    private readonly catalogService: CatalogService,
    private readonly sessionFacade: SessionFacade,
  ) {}

  getGuidesMenuForDesktop(): Observable<GenericMenuViewModel> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.store.select(selectGuidesDesktopMenu);
          }
          return EMPTY;
        }),
      );
  }

  getGuidesMenuForMobile(): Observable<GenericNavigationElement> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.store.select(selectGuidesMobileMenu);
          }
          return EMPTY;
        }),
      );
  }

  getCategoriesMenuForDesktop(): Observable<GenericMenuViewModel> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.getCategoriesMenuModel();
          }
          return EMPTY;
        }),
      );
  }

  getCategoriesMenuForMobile(): Observable<GenericNavigationElement> {
    return this.sessionFacade
      .isFeatureEnabled(FeatureFlag.BAYMARD_NAVIGATION)
      .pipe(
        switchMap((isEnabled: boolean) => {
          if (isEnabled) {
            return this.getCategoriesMenuModelForMobile();
          }
          return EMPTY;
        }),
      );
  }

  private getCategoriesMenuModel(): Observable<GenericMenuViewModel> {
    this.catalogService.loadCategoryTree(this.rootCategoryKey);
    return this.catalogService
      .observeCategoryResponse(this.rootCategoryKey)
      .pipe(
        filter((superCategory) => superCategory != null),
        map((categoriesFromRoot) => {
          const categoriesMenu: GenericMenuViewModel = {
            title: this.menuCategoriesTitle,
            ariaLabel: this.menuCategoriesTitle,
            topLevel: true,
            icon: NaooConstants.icons.categories_icon.name,
            menuElements: [
              {
                name: undefined,
                route: NaooConstants.CATEGORIES_PATH,
                isViewAll: true,
              },
            ],
          };

          categoriesFromRoot.subCategories.forEach((category) => {
            categoriesMenu.menuElements.push({
              name: category.name,
              isViewAll: false,
              route: `${NaooConstants.CATEGORIES_PATH}/${category.categoryKey}`,
            });
          });

          return categoriesMenu;
        }),
      );
  }

  private getCategoriesMenuModelForMobile(): Observable<GenericNavigationElement> {
    this.catalogService.loadCategoryTree(this.rootCategoryKey);
    return this.catalogService
      .observeCategoryResponse(this.rootCategoryKey)
      .pipe(
        filter((superCategory) => superCategory != null),
        map((categoriesFromRoot) => ({
          name: this.menuCategoriesTitle,
          showBackNavigation: true,
          viewAllRoute: NaooConstants.CATEGORIES_PATH,
          childNavigation: categoriesFromRoot.subCategories.map((category) => ({
            name: category.name,
            route: `${NaooConstants.CATEGORIES_PATH}/${category.categoryKey}`,
          })),
        })),
      );
  }
}
