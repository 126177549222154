import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GenericNavigationElement } from '../generic-navigation-model';
import { Router, RouterLink } from '@angular/router';
import { ModalHeaderComponent } from '../../modal-header/modal-header.component';
import { MatListModule } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NaooLocalizedOrStringDefaulterPipe } from '../../localized-string-defaulter/naoo-localized-or-string-defaulter.pipe';

@Component({
  selector: 'naoo-generic-navigation-menu',
  templateUrl: './generic-navigation-menu.component.html',
  styleUrl: './generic-navigation-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalHeaderComponent,
    MatListModule,
    RouterLink,
    TranslateModule,
    MatIcon,
    NaooLocalizedOrStringDefaulterPipe,
  ],
})
export class GenericNavigationMenuComponent {
  @Input() menuNavigation: GenericNavigationElement;
  @Input() parentMenuName: string;
  @Output() closeModalEvent = new EventEmitter();
  @Output() backNavigationEvent = new EventEmitter();
  protected childNavigation?: GenericNavigationElement;

  constructor(private readonly router: Router) {}

  closeModal() {
    this.closeModalEvent.emit();
  }

  callAction(action: () => void) {
    action();
    this.closeModal();
  }

  openSubmenu(option: GenericNavigationElement) {
    this.childNavigation = option;
  }

  backNavigation() {
    if (this.menuNavigation?.backNavigationRoute) {
      this.router.navigateByUrl(this.menuNavigation.backNavigationRoute);
    } else {
      this.childNavigation = undefined;
    }
  }

  get subMenuNavigation() {
    return this.childNavigation;
  }
}
