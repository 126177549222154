import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  materialCutoffAdapter,
  MaterialCutoffRecordState,
  MaterialCutoffRecordStatus,
  MaterialCutoffState,
} from './material-cutoff.state';
import { Dictionary } from '@ngrx/entity';
import { selectCurrentSystem } from '../session/session.selectors';

const selectMaterialCutoffFeature =
  createFeatureSelector<MaterialCutoffState>('materialCutoff');

const selectMaterialCutoffState = createSelector(
  selectMaterialCutoffFeature,
  (materialCutoffState) => materialCutoffState.cutoffs,
);

export const selectAllMaterialCutoffRecords = createSelector(
  selectMaterialCutoffState,
  materialCutoffAdapter.getSelectors().selectAll,
);

export const selectMaterialCutoffEntities = createSelector(
  selectMaterialCutoffState,
  materialCutoffAdapter.getSelectors().selectEntities,
);

const selectHasLoadedAllCutoffs = createSelector(
  selectMaterialCutoffFeature,
  (materialCutoffState) => materialCutoffState.hasLoadedAllCutoffs,
);

export const selectConsiderInventory = createSelector(
  selectMaterialCutoffFeature,
  (materialCutoffState) => materialCutoffState.considerInventory,
);

export const selectAreCutoffsLoaded = createSelector(
  selectMaterialCutoffFeature,
  (cutoffState): boolean => cutoffState.considerInventory !== undefined,
);

export const selectMaterialCutoff = (cutoffCode: string) => {
  return createSelector(
    selectMaterialCutoffEntities,
    selectHasLoadedAllCutoffs,
    selectCurrentSystem,
    (
      records: Dictionary<MaterialCutoffRecordState>,
      hasLoadedAllCutoffs: boolean,
    ) => {
      const recordState = records[cutoffCode];

      if (isEntryLoading(records, cutoffCode, hasLoadedAllCutoffs)) {
        return undefined;
      }

      if (MaterialCutoffRecordStatus.Error === recordState?.status) {
        return null;
      } else if (recordState?.record) {
        return {
          timestamp: recordState.record.cutoffDateTime,
        };
      } else {
        return undefined;
      }
    },
  );
};

function isEntryLoading(
  records: Dictionary<MaterialCutoffRecordState>,
  cutoffCode: string,
  hasLoadedAllCutoffs: boolean,
): boolean {
  return !hasLoadedAllCutoffs;
}
